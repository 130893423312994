/**
 A fallback response for GET /billing/plans/v2, returning prices in USD.
 Used as a fallback in case we can't fetch prices from the API.

 Last updated: 29 July 2024
 Corresponding museum commit: e5462a811ce9cc8276fcf011d79ae0e2622d51e0
 CLI: curl -H 'CF-IPCountry: US' 'http://localhost:8080/billing/plans/v2'
 */
export const fallbackPriceResponse = {
    "freePlan": {
        "storage": 10737418240,
        "duration": 365,
        "period": "year"
    },
    "plans": [
        {
            "id": "50gb_monthly_v4",
            "androidID": "",
            "iosID": "50gb_monthly_v4",
            "stripeID": "price_1PdXVTG1ITnQlpAnuWHUXmSd",
            "storage": 53687091200,
            "price": "$2.99",
            "period": "month"
        },
        {
            "id": "50gb_yearly_v4",
            "androidID": "",
            "iosID": "50gb_yearly_v4",
            "stripeID": "price_1PdXVTG1ITnQlpAnvEULYo2g",
            "storage": 53687091200,
            "price": "$29.88",
            "period": "year"
        },
        {
            "id": "200gb_monthly_v4",
            "androidID": "",
            "iosID": "200gb_monthly_v4",
            "stripeID": "price_1PdZDaG1ITnQlpAnrPulGyKj",
            "storage": 214748364800,
            "price": "$5.99",
            "period": "month"
        },
        {
            "id": "200gb_yearly_v4",
            "androidID": "",
            "iosID": "200gb_yearly_v4",
            "stripeID": "price_1PdZDaG1ITnQlpAnvCz4Ad78",
            "storage": 214748364800,
            "price": "$59.88",
            "period": "year"
        },
        {
            "id": "1000gb_monthly_v4",
            "androidID": "",
            "iosID": "1000gb_monthly_v4",
            "stripeID": "price_1PdZaaG1ITnQlpAnCllPHJ8p",
            "storage": 1073741824000,
            "price": "$11.99",
            "period": "month"
        },
        {
            "id": "1000gb_yearly_v4",
            "androidID": "",
            "iosID": "1000gb_yearly_v4",
            "stripeID": "price_1PdZaaG1ITnQlpAnVb6RWe51",
            "storage": 1073741824000,
            "price": "$119.88",
            "period": "year"
        },
        {
            "id": "2000gb_monthly_v4",
            "androidID": "",
            "iosID": "2000gb_monthly_v4",
            "stripeID": "price_1PdZddG1ITnQlpAnIPudVEYK",
            "storage": 2147483648000,
            "price": "$23.99",
            "period": "month"
        },
        {
            "id": "2000gb_yearly_v4",
            "androidID": "",
            "iosID": "2000gb_yearly_v4",
            "stripeID": "price_1PdZddG1ITnQlpAnqeW19Vjd",
            "storage": 2147483648000,
            "price": "$239.88",
            "period": "year"
        }
    ]

};

export default fallbackPriceResponse;
