import Toggle from "components/Toggle";
import { Link } from "gatsby";
import ArrowRight from "images/homepage/ArrowRight";
import React, { ReactNode, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { BillingPlans, IPlan, fetchPlans } from "utils/plan-service";
import { OS, getDeviceOS } from "utils/detect-os";
import { DownloadURL } from "constants/download";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const Label = styled.div<{ active: boolean }>`
    font-weight: 700;

    & > span {
        transition: opacity 0.25s ease-out;
        opacity: ${(props) => (props.active ? "1" : "0.5")};
    }
`;

const LabelsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const CardWrapper = styled.div`
    background: linear-gradient(180deg, #d8d8d855 0%, #a0e2b7 100%);
    padding: 2px;
    border-radius: 16px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex: 1;
    width: 75%;
    margin: 0 auto;

    @media (min-width: 992px) {
        width: 100%;
    }
`;

const Card = styled.div`
    border-radius: 16px;
    background: #fff;
    padding: 28px 50px 60px 50px;
    font-family: Gilroy;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (min-width: 768px) {
        padding: 28px;
    }

    @media (min-width: 1200px) {
        padding: 28px 20px;
    }

    @media (min-width: 1400px) {
        padding: 28px;
    }
`;

const PlanLabel = styled.div`
    color: #1d1d1f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.5;
    margin-bottom: 7px;
`;

const FreePlanLabel = styled.div`
    color: #08c225;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 7px;
`;

const PlanValue = styled.div<{ showAnnual?: boolean }>`
    color: #1d1d1f;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 21px;
    height: 49px;
    overflow: hidden;

    & > div {
        transition: transform 0.3s ease-out 0s;
    }

    &:hover > div {
        transform: translateY(
            ${(props) => (props.showAnnual ? "-49px" : "0px")}
        );
    }
`;

const PlanValueBold = styled(PlanValue)`
    font-weight: 700;
`;

const Popular = styled(PlanLabel)`
    color: #ffcd3f;
    font-weight: 700;
    opacity: 1;
    margin-top: -28px;
    background: linear-gradient(180deg, #20d861 0%, #18ab4c 100%);
    padding: 14px 0;
    text-align: center;
    width: 100px;
    color: #fff;
    border-radius: 0 0 12px 12px;
`;

const PlanValueMinor = styled.span`
    color: #1d1d1f;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 21px;
`;

const PlanPeriod = styled.span`
    color: #272522;
    font-family: "Gilroy";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const PlanDescription = styled.p`
    color: rgb(120, 120, 120);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    flex: 1;
    margin-top: 0px;
    margin-bottom: 32px;
`;

const PlanDescriptionItem = styled.p`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const PlanDescriptionCrossIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 6px;
    color: rgb(213, 8, 8);

    /* On the Bootstrap sm breakpoint, increase the icon size */
    @media (min-width: 576px) {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
`;

const PlanDescriptionCheckIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 6px;
    color: rgb(12, 163, 20);

    /* On the Bootstrap sm breakpoint, increase the icon size */
    @media (min-width: 576px) {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
`;

const PlanCTA = styled.a`
    font-family: "Gilroy";
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 100px;
    background: #434343;
    padding: 10px 26px;
    width: 160px;
    display: inline-flex;
    justify-content: space-between;
    transition:
        width 0.1s linear,
        background-color 0.1s linear;
    text-decoration: none;
    max-width: 172px;

    &:focus {
        outline: 4px solid rgba(0, 0, 0, 0.2);
    }

    &:hover {
        width: 100%;
        background-color: #08c225;
        color: #fff;
    }
`;

const PricingCarouselItem = styled(Carousel.Item)`
    transition: transform 0.2s ease;
`;

const FreeTrialSection = styled.button`
    margin-bottom: 72px;
    background-color: #00b33c;
    padding: 16px 26px;
    width: 240px;
    display: inline-flex;
    justify-content: space-between;
    font-family: "Gilroy";
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 100px;
    &:hover {
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 4px #1db954;
    }
`;

const PricingItem = styled.div.attrs({
    className: "px-1 text-start mb-3 d-flex blog-pricing-item",
})`
    display: flex;

    @media (min-width: 768px) {
        max-width: 33% !important;
    }

    @media (min-width: 1200px) {
        max-width: 20% !important;
    }
`;

const splitPrice = (price: string) => price.split(".");

const getMonthlyAmount = (annualPrice: string) => {
    const monthly = parseFloat(annualPrice) / 12;
    return monthly.toFixed(2) + "";
};

const getPlanCard = (
    plan: IPlan,
    shouldShowMonthly: boolean,
    title: string,
    description: ReactNode,
    tryNowURL: string,
    isPopularPlan?: boolean,
) => {
    const amount = plan.price.substring(1);
    const [major, minor] = splitPrice(
        shouldShowMonthly ? amount : getMonthlyAmount(amount),
    );
    const [hoverMajor, hoverMinor] = splitPrice(amount);
    const isFreePlan = plan.id === "free";

    return (
        <CardWrapper>
            <Card>
                {isPopularPlan ? (
                    <Popular>{title}</Popular>
                ) : isFreePlan ? (
                    <FreePlanLabel>Free</FreePlanLabel>
                ) : (
                    <PlanLabel>{title}</PlanLabel>
                )}
                <PlanValueBold className="mt-3">{plan.size} GB</PlanValueBold>
                {isFreePlan ? (
                    <PlanValue showAnnual={!shouldShowMonthly}>
                        <div>
                            {plan.price.substring(0, 1)}
                            {major}
                            <PlanPeriod>/mo</PlanPeriod>
                        </div>
                        <div>
                            {plan.price.substring(0, 1)}
                            {hoverMajor}
                            <PlanPeriod>/yr</PlanPeriod>
                        </div>
                    </PlanValue>
                ) : (
                    <PlanValue showAnnual={!shouldShowMonthly}>
                        <div>
                            {plan.price.substring(0, 1)}
                            {major}
                            {minor && minor !== "00" && (
                                <PlanValueMinor>.{minor}</PlanValueMinor>
                            )}
                            <PlanPeriod>/mo</PlanPeriod>
                        </div>
                        <div>
                            {plan.price.substring(0, 1)}
                            {hoverMajor}
                            {hoverMinor && (
                                <PlanValueMinor>.{hoverMinor}</PlanValueMinor>
                            )}
                            <PlanPeriod>/yr</PlanPeriod>
                        </div>
                    </PlanValue>
                )}
                {description}
                <PlanCTA id="pricing-try-it-now" href={tryNowURL}>
                    Try now <ArrowRight />
                </PlanCTA>
            </Card>
        </CardWrapper>
    );
};

const planDescriptionContent = [
    <PlanDescription>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            On-device AI search
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Curated memories
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Automatic backup
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            1 replica
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Community support
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCrossIcon>
                <XMarkIcon />
            </PlanDescriptionCrossIcon>
            Album sharing
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCrossIcon>
                <XMarkIcon />
            </PlanDescriptionCrossIcon>
            Family plan
        </PlanDescriptionItem>
    </PlanDescription>,
    <PlanDescription>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            On-device AI search
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Curated memories
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Automatic backup
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            3 replicas
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Dedicated support
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Album sharing
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Family plan
        </PlanDescriptionItem>
    </PlanDescription>,
    <PlanDescription>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            On-device AI search
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Curated memories
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Automatic backup
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            3 replicas
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Dedicated support
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Album sharing
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Family plan
        </PlanDescriptionItem>
    </PlanDescription>,
    <PlanDescription>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            On-device AI search
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Curated memories
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Automatic backup
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            3 replicas
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Dedicated support
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Album sharing
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Family plan
        </PlanDescriptionItem>
    </PlanDescription>,
    <PlanDescription>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            On-device AI search
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Curated memories
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Automatic backup
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            3 replicas
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Dedicated support
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Album sharing
        </PlanDescriptionItem>
        <PlanDescriptionItem>
            <PlanDescriptionCheckIcon>
                <CheckIcon />
            </PlanDescriptionCheckIcon>
            Family plan
        </PlanDescriptionItem>
    </PlanDescription>,
];

const getPlanCards = (
    filteredPlans: IPlan[],
    shouldShowMonthly: boolean,
    carousel: boolean,
    tryNowURL: string,
) => {
    return filteredPlans.map((plan, index) => {
        const isPopular = index === filteredPlans.length - 3;
        const title = ["Free", "Starter", "Popular", "Pro", "Pro+"];
        return carousel ? (
            <PricingCarouselItem key={plan.id}>
                {getPlanCard(
                    plan,
                    shouldShowMonthly,
                    title[index],
                    planDescriptionContent[index],
                    tryNowURL,
                    isPopular,
                )}
            </PricingCarouselItem>
        ) : (
            <PricingItem key={plan.id}>
                {getPlanCard(
                    plan,
                    shouldShowMonthly,
                    title[index],
                    planDescriptionContent[index],
                    tryNowURL,
                    isPopular,
                )}
            </PricingItem>
        );
    });
};
export default function PricingPlans() {
    const [billingPlans, setBillingPlans] = useState({} as BillingPlans);
    const [shouldShowMonthly, setShouldShowMonthly] = useState(false);

    useEffect(() => {
        fetchPlans().then(setBillingPlans);
    }, []);

    const handleToggle = function () {
        setShouldShowMonthly(!shouldShowMonthly);
    };

    const filterPeriod = shouldShowMonthly ? "month" : "year";
    const filteredPlans =
        billingPlans.plans?.filter((plan) => plan.period === filterPeriod) ??
        [];
    if (billingPlans.freePlan) {
        filteredPlans.unshift({
            id: "free",
            size: billingPlans.freePlan!.size,
            price: `${billingPlans.plans?.[0].price.substring(0, 1)}0`,
            period: "year",
        });
    }

    const [tryNowURL, setTryNowURL] = useState<string>("#download");

    useEffect(() => {
        const os = getDeviceOS();

        if (os === OS.Android) {
            setTryNowURL(DownloadURL.googlePlay);
        } else if (os === OS.IOS) {
            setTryNowURL(DownloadURL.appStore);
        } else {
            setTryNowURL("#download");
        }
    }, []);

    return (
        <>
            <LabelsContainer>
                <Label active={shouldShowMonthly}>
                    <span>Monthly</span>
                </Label>
                <div>
                    <Toggle
                        isOn={!shouldShowMonthly}
                        offLabel=""
                        onLabel=""
                        onToggle={handleToggle}
                    />
                </div>
                <Label active={!shouldShowMonthly}>
                    <span>Annually</span>
                </Label>
            </LabelsContainer>
            <div className="mt-4 d-md-none text-start">
                <Carousel interval={null} className="carousel-dark">
                    {getPlanCards(
                        filteredPlans,
                        shouldShowMonthly,
                        true,
                        tryNowURL,
                    )}
                </Carousel>
            </div>
            <div className="row mt-4 d-none d-md-flex justify-content-center">
                {getPlanCards(
                    filteredPlans,
                    shouldShowMonthly,
                    false,
                    tryNowURL,
                )}
            </div>
        </>
    );
}
